import dynamic from 'next/dynamic'
import React, { Component, ErrorInfo, ReactNode } from 'react'
import {
  ILoggerContext,
  LoggerContext
} from 'src/context/LoggerProvider/useLogger'

const ClientError = dynamic(() => import('../screens/Errors/ClientError'))

interface Props {
  children?: ReactNode
}

interface State {
  hasError: boolean
  error?: Error | undefined
}

class ErrorBoundary extends Component<Props, State> {
  static contextType = LoggerContext

  public state: State = {
    hasError: false
  }

  public static getDerivedStateFromError(error: Error): State {
    return { hasError: true, error }
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    const logger = this.context as ILoggerContext
    logger?.error('Uncought Error', { errorInfo }, error)
  }

  public render() {
    if (this.state.hasError) {
      return <ClientError />
    }

    return this.props.children
  }
}

export default React.memo(ErrorBoundary)
